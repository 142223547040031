/* Hide Audio Player Time */
.rhap_time {
  display: none
}

.header-fixed {
  position: sticky;
  top: 0px;
  z-index: 100;
}

.noFlexWrap {
  flex-wrap: nowrap;
}

.euiComboBox__inputWrap {
  flex-wrap: nowrap !important;
}

.zIndex9001 {
  z-index: 9001;
}

html {
  /** background-color: #fafbfd;
   background-image: url(/MOI-Iraq.png); */
  background-repeat: no-repeat;
  background-position: center;
}

.euiPage {
  background-color: #fafbfd30;
}
